import { graphql, StaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const INDEX_QUERY = graphql`
  {
    images: allFile(filter: { relativeDirectory: { eq: "index" } }) {
      ...FluidImageData
    }
    logo: allFile(filter: { relativePath: { eq: "logo.png" } }) {
      edges {
        node {
          childImageSharp {
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`

const nodesToImages = edges =>
  edges.map(edge => (
    <div key={edge.node.id} className="column">
      <figure className="image">
        <Img fluid={edge.node.childImageSharp.fluid} alt={edge.node.name} />
      </figure>
    </div>
  ))

const IndexPage = props => (
  <Layout>
    <SEO
      title="Domov"
      description="Kvalitné vstavané skrine a šatníky na mieru, parkety a schody za výbornú cenu. Dodanie do 15 dní od zloženia zálohy. Bezplatná cenová ponuka."
      keywords={[
        `Skrine`,
        `Vstavané skrine`,
        `Parkety`,
        `Schody`,
        `Interiér`,
        `Nábytok`,
      ]}
    />

    <StaticQuery
      query={INDEX_QUERY}
      render={data => (
        <>
          <div className="columns is-centered">
            <div className="column has-text-centered is-12">
              <figure className="image">
                <Img
                  fixed={data.logo.edges[0].node.childImageSharp.fixed}
                  alt="logo"
                />
              </figure>
            </div>
          </div>

          <section>
            <h1 className="title">Vstavané skrine a šatníky na mieru</h1>
            <hr />
            <h2 className="subtitle">Kvalita za výbornú cenu</h2>
            <p>
              Každý priestor je jedinečný, a preto by ste márne hľadali 2
              rovnaké vstavané skrine medzi našimi{' '}
              <Link to="/galeria">
                <b>dokončenými projektami.</b>
              </Link>{' '}
              Na základe dlhoročných skúsenosti ponúkame našim zákazníkom
              vstavané skrine, parkety a šatníky na mieru z rôznych druhov{' '}
              <Link to="/vzorkovnik">
                <b>materiálov a skiel</b>
              </Link>
              . Na výrobu našich produktov používame kvalitný materiál od
              rakúskeho výrobcu <b>Egger</b>, s ktorým mame iba pozitívne
              skúsenosti. Našim cieľom je dokázať zákazníkom, že naše produkty
              spĺňajú nielenže praktickú funkciu, ale taktiež skrášľujú
              interiér. Preto budeme veľmi radi, ak nás v prípade potreby{' '}
              <Link to="/kontakt">
                <b>oslovíte</b>
              </Link>{' '}
              a my s radosťou premeníme Vašu predstavu na realitu.
            </p>
            <hr />
            <h2 className="subtitle">Rýchle dodanie</h2>
            <p style={{ marginBottom: '1em' }}>
              Našu rodinnú firmu sme si vybudovali najmä tvrdou prácou, ktorá sa
              odzrkadlila na dobrom mene šírenom našimi zákazníkmi, pre ktorých
              robíme maximum. Naše úsilie sa nám vracia v podobe pozitívnych
              referencií a odporúčaní od spokojných klientov - kvalitné vstavané
              skrine a šatníky pritom ponúkame za veľmi dobré ceny.
            </p>
            <p>
              <b>
                Dodanie s montážou vstavanej skrine garantujeme do 15 pracovných
                dní od zloženia zálohy vo výške 60% z ceny.
              </b>
            </p>
            <br />
            <div className="columns">{nodesToImages(data.images.edges)}</div>
          </section>
        </>
      )}
    />
  </Layout>
)

export default IndexPage
